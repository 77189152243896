import * as React from 'react'
import AppointmentSuccessPage from '/src/templates/AppointmentSuccessPage'

const Page = ({ postInfo }) => {
    const post = {
        title: 'Veriform Submission Success',
        indexStatus: 'noindex',
        followStatus: 'nofollow'
    }

    return <AppointmentSuccessPage postInfo={postInfo} post={post} />
}

export default Page

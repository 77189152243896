import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import faq from '/src/data/faq'
import faqOne from '/src/data/faqOne'

const Accordion = loadable(() => import('/src/components/Accordion/Accordion'))
const Awards = loadable(() => import('/src/components/Awards'))
const Callout = loadable(() => import('/src/components/Callout'))
const CalloutWithImage = loadable(() =>
    import('/src/components/CalloutWithImage')
)
const Calls = loadable(() => import('/src/components/Calls'))
const CardDetails = loadable(() => import('/src/components/CardDetails'))
const Footer = loadable(() => import('/src/components/Footer'))
const Section = loadable(() => import('/src/components/Section'))
const Seo = loadable(() => import('/src/components/Seo'))
const SuccessHeader = loadable(() => import('/src/components/SuccessHeader'))
const Videos = loadable(() => import('/src/components/Videos'))

const AppointmentSuccessPage = ({ postInfo, post }) => {
    return (
        <>
            <Seo postInfo={postInfo} post={post} />

            <main>
                <SuccessHeader appointment={true} />

                <Section>
                    <Accordion data={faqOne} />
                </Section>

                <Videos />

                <Awards />

                <CardDetails />

                <Calls />

                <Callout>
                    <h2>
                        Want to know more about <span>Life Insurance</span>?
                    </h2>
                    <p>
                        Check out some of our Frequently Asked Questions below
                    </p>
                </Callout>

                <Section variant="alt">
                    <Accordion data={faq} />

                    <CalloutWithImage />
                </Section>
            </main>

            <Footer />
        </>
    )
}

AppointmentSuccessPage.propTypes = {
    /**
     * Post info object
     */
    postInfo: PropTypes.object.isRequired,
    /**
     * Post object
     */
    post: PropTypes.object.isRequired
}

export default AppointmentSuccessPage
